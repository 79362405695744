import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { getAgents } from "../../../Services/transactionsService";
import BackHeader from "../../../Utils/Back Header/back_header";
import Individual from "./individual";

const NairaDeposit = ({ authToken }) => {
  const [agents, setAgents] = useState([]);
  const [loadingAgents, setLoadingAgents] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    const getAgent = async () => {
      if (mounted) {
        try {
          const data = await getAgents(authToken);
          setAgents(data.data.data);
          setErrorLoading(false);
          setLoadingAgents(false);
        } catch (error) {
          setErrorLoading(true);
          setLoadingAgents(false);
        }
      }
    };
    getAgent();
    return () => (mounted = false);
  }, []);

  const right_section = (
    <Section>
      <TransactionContainer>
        <Link to="/dashboard/transactions">
          <TransactionButton>Transactions</TransactionButton>
        </Link>
      </TransactionContainer>
    </Section>
  );

  return (
    <Container>
      <BackHeader disabled right_section={right_section} />
      <BodyContainer>
        <ButtonContainer>
          <Button> Deposit Naira</Button>
        </ButtonContainer>
        {loadingAgents ? (
          <Spinner
            name="folding-cube"
            color="#000070"
            className="spin_kit_icon"
          />
        ) : errorLoading ? (
          <div className="error_loading">
            An error occurred, please try again
          </div>
        ) : (
          agents.map((item, index) => (
            <Individual
              key={index}
              name={item.first_name}
              id={item.id}
              accounts={item.accounts}
              successful={item.successful}
              declined={item.declined}
              amount={item?.naira_wallet?.amount}
            />
          ))
        )}
      </BodyContainer>
    </Container>
  );
};

export default NairaDeposit;

const Container = styled.div``;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 1em auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 2em auto;
  width: 100%;
`;

const Button = styled.button`
  background: #000070;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  color: #fff;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const TransactionContainer = styled.div`
  margin: 0 0.5em;
`;

const TransactionButton = styled.button`
  cursor: pointer;
  background: #ffffff;
  border: 0.5px solid #000070;
  border-radius: 5px;
  padding: 10px;
`;

import React from "react";
import { NavLink } from "react-router-dom";
import "./middle.css";
import dashboard from "../../../img/dashboard 1.png";
import wallet from "../../../img/wallet.png";
import transactions from "../../../img/transactions.png";
import rates from "../../../img/rates icon.png";
import history from "../../../img/transaction history icon.png";

function SidebarMiddle(props) {
  return (
    <div className="middle">
      <NavLink to="/dashboard" className="middle_options">
          <div className="middle_icon_container">
            <img className="sidebar_icon" src={dashboard} alt="dashboard" />
          </div>
          <div className="middle_description">Dashboard</div>
      </NavLink>
      <NavLink to="/dashboard/portfolio" className="middle_options">
          <div className="middle_icon_container">
            <img className="sidebar_icon" src={wallet} alt="wallet" />
          </div>
          <div className="middle_description">Wallet Portfolio</div>
      </NavLink>
      <NavLink to="/dashboard/bills" className="middle_options">
          <div className="middle_icon_container">
            <img
              className="sidebar_icon"
              src={transactions}
              alt="transactions"
            />
          </div>
          <div className="middle_description">Pay Bills</div>
      </NavLink>
      <NavLink to="/dashboard/assets" className="middle_options">
          <div className="middle_icon_container">
            <img className="sidebar_icon" src={rates} alt="rates" />
          </div>
          <div className="middle_description">Trade Assets</div>
      </NavLink>
      <NavLink to="/dashboard/transactions" className="middle_options">
          <div className="middle_icon_container">
            <img className="sidebar_icon" src={history} alt="history" />
          </div>
          <div className="middle_description">Transaction History</div>
      </NavLink>
    </div>
  );
}

export default SidebarMiddle;

import React from "react";
import styled from "styled-components";
import ButtonComponent from "./button";
import Range from "./range";
import SellerDetails from "./sellerDetails";

const Individual = ({ name, id, accounts, successful, declined, amount }) => {
  return (
    <Container>
      <SellerDetails name={name} successful={successful} declined={declined} />
      <Range amount={amount}/>
      <ButtonComponent id={id} accounts={accounts} />
    </Container>
  );
};

export default Individual;

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr 0.4fr;
  margin: 1em auto;
  width: 100%;
  padding: 5px;
  background: #fcfcfe;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

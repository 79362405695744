import React from "react";
import styled from "styled-components";

const InputComponent = ({ name, type = "text", value, onChange }) => {
  return (
    <Container>
      <Label htmlFor={name}>{name}</Label>
      <Input
        type={type}
        name={name}
        id={name}
        onChange={onChange}
        value={value}
      />
    </Container>
  );
};

export default InputComponent;

const Container = styled.div`
  margin: 2em auto;
`;

const Label = styled.label`
  display: block;
  margin-left: 5px;
`;

const Input = styled.input`
  display: block;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 95%;
`;

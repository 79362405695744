import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./success_ad.css";
import success_ad_icon from "../../../img/success ad icon.svg";
import BackHeader from "../../../Utils/Back Header/back_header";

function SuccessAd(props) {
  const location = useLocation();

  const message = location?.state?.message;
  return (
    <div>
      <BackHeader back_link={"/dashboard"} />
      <div className="success_ad_container">
        <div className="success_ad_icon_container">
          <img src={success_ad_icon} alt="success_ad_icon" />
        </div>
        <h3>Success!</h3>
        <h4>{message}</h4>
        <div className="success_ad_button_container">
          <Link to="/dashboard">
            <button id="success_ad_link">Continue</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SuccessAd;

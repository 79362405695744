import React from "react";
import { useAuthState } from "../../../../contexts";
import {numberWithCommas} from '../../../Utils/Functions/function'
import "./left.css";

function NavLeft(props) {
  const user = useAuthState();

  const amount = user?.user?.naira_wallet?.amount

  return (
    <div className="left">
      <p>₦ {numberWithCommas(amount)}</p>
      <p className="grey-text">Wallet Balance</p>
    </div>
  );
}

export default NavLeft;

import React from "react";

export const Form = ({ src }) => {
  return (
    <img
        src={src}
        alt='pop'
        style={{maxWidth:'100%'}}
      />
  );
};
export default Form;

import React from "react";
import "./right.css";
import notification from "../../../img/Vector (2).png";
import logout_icon from "../../../img/logout.png";
import user_icon from "../../../img/user.png";
import { logout, useAuthDispatch, useAuthState } from "../../../../contexts";

function NavRight(props) {
  const user = useAuthState();
  const dispatch = useAuthDispatch();
  const {
    user: { username},
  } = user;

  const handleLogout = () => {
    setTimeout(() => {
      logout(dispatch);
    }, 1000);
  };

  return (
    <div className="right">
      <div className="user_column">
        <div className="user_details">
          <p className="username_text">{username}</p>
          <p className="grey-text">Hi there</p>
        </div>
        <div className="user_icon">
          <img className="icons dashboard_user_dp" src={user_icon} alt="user" id="user_icon" />
        </div>
      </div>
      <div className="icon_column">
        <div className="notification_icon_container">
          <img className="icons" src={notification} alt="notification" />
        </div>
        <div className="log_out_icon_container" onClick={() => handleLogout()}>
          <img className="icons" src={logout_icon} alt="log-out" />
        </div>
      </div>
    </div>
  );
}

export default NavRight;

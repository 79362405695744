import { useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Navbar/navbar";
import Sidebar from "./Sidebar/sidebar";
import Header from "../Utils/Header/header";
import { useAuthState } from "../../contexts";
import NairaDeposit from "./Peer to Peer/Naira Deposit";
import BuyForm from "./Peer to Peer/Buy Form/BuyForm";
import UploadProof from "./Peer to Peer/Upload Image/upload_proof";
import SuccessAd from "./Peer to Peer/Success Ad/success_ad";
import Transactions from "./Peer to Peer/Transactions";

function DashboardIndex(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const userDetails = useAuthState();
  const authToken = userDetails?.token?.token;

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  window.onclick = () => setSidebarOpen(false);

  return (
    <div className="body">
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <div className="main_section">
        <Header header={"Peer to Peer Naira Deposit"} />
        <div className="peer_to_peer_container">
          <Switch>
            <Route path="/dashboard/transactions">
              <Transactions authToken={authToken}/>
            </Route>
            <Route path="/dashboard/success">
              <SuccessAd />
            </Route>
            <Route path="/dashboard/upload">
              <UploadProof authToken={authToken} />
            </Route>
            <Route path="/dashboard/deposit">
              <BuyForm authToken={authToken} />
            </Route>
            <Route path="/dashboard">
              <NairaDeposit authToken={authToken} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default DashboardIndex;

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const ButtonComponent = ({ id, accounts }) => {
  const history = useHistory();
  const handleDeposit = () => {
    history.push({
      pathname: "/dashboard/deposit",
      state: { id, accounts },
    });
  };

  return (
    <ButtonContainer>
      <Button onClick={handleDeposit}>Deposit</Button>
    </ButtonContainer>
  );
};

export default ButtonComponent;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 1em;
  }
`;

const Button = styled.button`
  background: #000070;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  width: 50%;
  margin: auto;
  padding: 8px;
  cursor: pointer;
`;

import React from "react";
import "./bottom.css";
import { Link } from "react-router-dom";
import settings from "../../../img/settings.png";
import logout_icon from "../../../img/logout.png";
import { logout, useAuthDispatch } from "../../../../contexts";

function SidebarBottom(props) {
  const dispatch = useAuthDispatch()

  const handleLogout = () => {
    setTimeout(() => {
      logout(dispatch);
    }, 1000);
  };

  return (
    <div className="bottom">
      <Link to="/dashboard/user" className="bottom_options">
          <div className="bottom_icon_container">
            <img className="sidebar_icon" src={settings} alt="settings" />
          </div>
          <div className="bottom_description">Account</div>
      </Link>
      <div className="bottom_options" onClick={() => handleLogout()}>
        <div className="bottom_icon_container">
          <img className="sidebar_icon" src={logout_icon} alt="logout" />
        </div>
        <div className="bottom_description">Logout</div>
      </div>
    </div>
  );
}

export default SidebarBottom;

import React, { useState } from "react";
import styled from "styled-components";
import InputComponent from "./Input";
import { loginUser, useAuthDispatch, useAuthState } from "../../contexts";
import ButtonComponent from "./Button";

const FormComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginDetails = { email, password };
    setErrorMessage("");
    try {
      let response = await loginUser(dispatch, loginDetails);
      if (!response.user) return;
      // const { state } = location;
      // window.location = state ? state.from.pathname : "/dashboard";
      window.location = "/dashboard";
    } catch (error) {
      handleFailedLogin();
    }
  };

  const handleFailedLogin = () => {
    const errorMessage = (
      <div style={{ textAlign: "center", margin: "2em auto" }}>
        <h3>These credentials do not match our records</h3>
      </div>
    );
    setErrorMessage(errorMessage);
    setTimeout(() => {
      setErrorMessage("");
    }, 60000);
  };

  return (
    <FormContainer>
      <Header>Enter your email and password to proceed</Header>
      <Form onSubmit={handleSubmit}>
        <InputComponent value={email} name={"Email"} onChange={handleEmail} />
        <InputComponent
          value={password}
          name={"Password"}
          type={"password"}
          onChange={handlePassword}
        />
        <ButtonComponent text="Submit" disabled={loading}/>
      </Form>
      {errorMessage}
    </FormContainer>
  );
};

export default FormComponent;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 40%;
  background: #ffffff;
  border-radius: 10px;
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
`;

const Header = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  width: 80%;
  margin: auto;
`;

import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import BuyInput from "./BuyInput";
import BuyButton from "./BuyButton";
import { buyNaira } from "../../../Services/transactionsService";
import BackHeader from "../../../Utils/Back Header/back_header";

const BuyForm = ({ authToken }) => {
  const [amount, setAmount] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [formFail, setFormFail] = useState("");
  const location = useLocation();
  const history = useHistory();
  const { id, accounts } = location.state;

  const handleChange = ({ target: { value } }) => {
    setAmount(value);
    value ? setButtonDisabled(false) : setButtonDisabled(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    setInputDisabled(true);
    let formData = new FormData();
    formData.append("agent_id", id);
    formData.append("amount", amount);
    submit(formData);
  };

  const submit = async (info) => {
    try {
      const data = await buyNaira(authToken, info);
      console.log(data.data);
      handleResponse(data.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleResponse = (response) => {
    if (response.success) {
      const { reference, id } = response;
      return setTimeout(() => {
        history.replace({
          pathname: "/dashboard/upload",
          state: {
            reference,
            amount,
            id,
            accounts,
          },
        });
      }, 500);
    }
    return handleError(response);
  };

  const handleError = (error) => {
    error.msg
      ? setFormFail(
          <div
            style={{
              color: "red",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>{error.msg}</h3>
          </div>
        )
      : setFormFail(
          <div
            style={{
              color: "red",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>Something went wrong. Please try again</h3>
          </div>
        );
    setButtonDisabled(false);
    setInputDisabled(false);
  };

  return (
    <>
      <BackHeader back_link={"/dashboard"} />
      <Container>
        <Form onSubmit={handleSubmit}>
          <h3 style={{ marginBottom: "3em" }}>
            Enter the amount of naira that you want to deposit
          </h3>
          <BuyInput
            name={`NGN`}
            value={amount}
            onChange={handleChange}
            disabled={inputDisabled}
          />
          <BuyButton text={`Deposit`} disabled={buttonDisabled} />
        </Form>
      </Container>
      {formFail}
    </>
  );
};

export default BuyForm;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 1em auto;
  @media (max-width:768px){
    width: 90%;
  }
`;

import React from "react";
import styled from "styled-components";

const BuyInput = ({ name, value, onChange, disabled }) => {
  return (
    <Container>
      <Label htmlFor={name}>Amount</Label>
      <InputContainer>
        <Para>{name}</Para>
        <Input
          type="number"
          name={name}
          id={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      </InputContainer>
    </Container>
  );
};

export default BuyInput;

const Container = styled.div`
  width: 80%;
  margin: 1em auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Label = styled.label`
  font-size: 1.2rem;
  font-weight: bold;
`;

const InputContainer = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 112, 0.75);
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: 0.5em 1em;
  @media (max-width: 768px) {
    padding: 0.5em;
  }
`;

const Para = styled.p`
  border-right: 1px solid rgba(0, 0, 112, 0.25);
  padding: 0.5em;
`;

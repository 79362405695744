import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./upload_proof.css";
import form_info from "../../../img/form info.svg";
import upload_proof_icon from "../../../img/upload proof icon.svg";
import BackHeader from "../../../Utils/Back Header/back_header";
import { uploadPop } from "../../../Services/transactionsService";

function UploadProof({ authToken }) {
  const location = useLocation();
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [fileToBeSent, setFileToBeSent] = useState("");
  const [formFail, setFormFail] = useState("");
  const { reference, amount, accounts, id } = location.state;
  const { account_name, account_number, bank_name } = accounts[0];

  const displayAccount = () => {
    return (
      <div className="seller_bank_details_container">
        <div className="seller_bank_details_row">
          <div className="seller_bank_details_label">Account name</div>
          <div className="seller_bank_details_value">{account_name}</div>
        </div>
        <div className="seller_bank_details_row">
          <div className="seller_bank_details_label">Account number</div>
          <div className="seller_bank_details_value">{account_number}</div>
        </div>
        <div className="seller_bank_details_row">
          <div className="seller_bank_details_label">Bank name</div>
          <div className="seller_bank_details_value">{bank_name}</div>
        </div>
      </div>
    );
  };

  const onChange = () => {
    const filesSelected = document.getElementById("inputFileToLoad").files;
    if (filesSelected.length > 0) {
      const fileToLoad = filesSelected[0];

      const fileReader = new FileReader();

      fileReader.onload = function (fileLoadedEvent) {
        const srcData = fileLoadedEvent.target.result; // <--- data: base64

        const newImage = document.createElement("img");
        newImage.src = srcData;

        document.getElementById("imgTest").innerHTML = newImage.outerHTML;
        setFileToBeSent(document.getElementById("imgTest").innerHTML);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
    setButtonDisabled(false);
  };

  const sliceFileToBeSent = (str) => {
    let p = str.split("base64,");
    return p[1].slice(0, -2);
  };

  const handleSubmit = () => {
    setButtonDisabled(true);
    let formData = new FormData();
    formData.append("image", sliceFileToBeSent(fileToBeSent));
    formData.append("transaction_id", id);
    submit(formData);
  };

  const submit = async (info) => {
    try {
      const data = await uploadPop(authToken, info);
      handleResponse(data.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleResponse = (data) => {
    if (data.success) {
      return history.replace({
        pathname: "/dashboard/success",
        state: {
          message:
            "Your POP has been uploaded successfully. Your wallet would be credited soon",
        },
      });
    }
    return handleError(data);
  };

  const handleError = (error) => {
    error.msg
      ? setFormFail(
          <div
            style={{
              color: "red",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>{error.msg}</h3>
          </div>
        )
      : setFormFail(
          <div
            style={{
              color: "red",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>Something went wrong. Please try again</h3>
          </div>
        );
    setButtonDisabled(false);
  };

  return (
    <div>
      <BackHeader
        back_link={"/dashboard"}
        disabled={true}
      />
      <div className="upload_proof_container">
        <h3>To complete this trasaction</h3>
        <h3>
          Send <span className="green_text">NGN{amount}</span> with your
          reference number <span className="grey_text">{reference}</span> as the
          transaction description to {account_name}
        </h3>
        <div className="seller_bank_details_section">
          <h3>Account Details</h3>
          {displayAccount()}
        </div>
        <p>Then click on the “payment made” button</p>
        <div className="upload_proof_info_container">
          <img src={form_info} alt="form_info" />
          <p>Your description should contain your reference number.</p>
        </div>
        <div className="upload_proof_input_container">
          <img src={upload_proof_icon} alt="upload_proof_icon" />
          <p>Upload proof of payment</p>
          <input type="file" id="inputFileToLoad" onChange={onChange} />
        </div>
        <div id="imgTest"></div>
        <div className="upload_proof_button_container">
          <button
            className="upload_proof_button"
            onClick={handleSubmit}
            disabled={buttonDisabled}
          >
            Payment made
          </button>
        </div>
      </div>
      {formFail}
    </div>
  );
}

export default UploadProof;

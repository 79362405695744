import React from "react";
import "./mobileNav.css";
import logo from "../../../img/Vector (2).png";
import hamburger from "../../../img/mdi_menu.png";

function MobileNav({ openSidebar }) {
  const handleClick = (e) =>{
    e.stopPropagation()
    openSidebar()
  }
  return (
    <div className="mobile_nav">
      <div className="hamburger_container">
        <img
          onClick={handleClick}
          src={hamburger}
          alt="hamburger"
          className="hamburger"
          id="hamburger"
        />
      </div>
      <div className="nav_logo">
        <img src={logo} alt="logo" className="mobile_logo" />
      </div>
    </div>
  );
}

export default MobileNav;

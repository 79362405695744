import React from "react";
import styled from "styled-components";
import { numberWithCommas } from "../../../Utils/Functions/function";

const Range = ({amount}) => {
  return (
    <Container>
      <StatusContainer>
        <Para>Status</Para>
        <Header>Active</Header>
      </StatusContainer>
      <RangeContainer>
        <Para>Available</Para>
        <Header>₦{numberWithCommas(amount)}</Header>
      </RangeContainer>
    </Container>
  );
};

export default Range;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  text-align: center;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Para = styled.p``;

const Header = styled.h3``;

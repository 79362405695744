import React from "react";
import FormComponent from "./Form";
import LogoContainer from "./LogoContainer";
import { useAuthState } from "../../contexts";
import { Redirect } from "react-router";

const LoginForm = () => {
  const { user } = useAuthState();

  return user ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className="login_container">
      <LogoContainer />
      <FormComponent />
    </div>
  );
};

export default LoginForm;

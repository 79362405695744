import React from "react";
import { useAuthState } from "../../../contexts";
import "./header.css";

function Header({ header }) {
  const userDetails = useAuthState();

  const amount = userDetails?.user?.bitcoin_wallet?.balance;
  return (
    <div className="section_headers">
      <div className="header_container">
        <div className="header_left">
          <div className="headers_head">
            <h2>{header}</h2>
          </div>
        </div>
        <div className="header_right">
          <div className="wallet_balance_container">
            <h4 className="grey_color">Wallet Balance</h4>
            <h3>{amount ? amount : 0} BTC</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import React from "react";
import styled from "styled-components";

const ButtonComponent = ({ text, disabled }) => {
  return (
    <Container>
      <Button disabled={disabled}>{text}</Button>
    </Container>
  );
};

export default ButtonComponent;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  background: #000070;
  border-radius: 5px;
  width: 50%;
  margin: 1em auto;
  padding: 0.5em;
  color: #ffffff;
`;

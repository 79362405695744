import React from "react";
import styled from "styled-components";
import user_icon from "../../../img/user.svg";

function SellerDetails({ name,successful, declined }) {
  return (
    <SellerContainer>
      <IconContainer>
        <Image src={user_icon} alt="user" />
      </IconContainer>
      <DetailsContainer>
        <Header>{name}</Header>
        <ParaGreen>
          {successful} <Span>successful transactions</Span>
        </ParaGreen>
        <ParaRed>
          {declined} <Span>declined transactions</Span>
        </ParaRed>
      </DetailsContainer>
    </SellerContainer>
  );
}

export default SellerDetails;

const SellerContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
      justify-content: center;
      margin-bottom: 1em;
  }
`;

const IconContainer = styled.div``;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const Header = styled.h3``;

const ParaGreen = styled.p`
  color: #1f7928;
`;

const ParaRed = styled.p`
  color: #af0707;
`;

const Span = styled.span`
  font-size: smaller;
`;

import React from "react";
import styled from "styled-components";
import Moment from "react-moment";
import Container from "../../../Utils/Modal Container/modalContainer";

const IndividualTransaction = ({ amount, pop, date }) => {
  const getPoP = (id) => {
    return id ? `http://dantowntest.com.ng/storage/pop/${id}` : null;
  };

  return (
    <Section>
      <Amount>
        <Para>Amount</Para>
        <Header>₦{amount}</Header>
      </Amount>
      <DateCon>
        <Para>Date</Para>
        <Header>
          <Moment format="hh:mm:ssa DD/MM/YYYY">{date}</Moment>
        </Header>
      </DateCon>
      <PopCon>
        <Container src={getPoP(pop[0]?.path)} />
      </PopCon>
    </Section>
  );
};

export default IndividualTransaction;

const Section = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr 0.4fr;
  margin: 1em auto;
  width: 100%;
  padding: 5px;
  background: #fcfcfe;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Amount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DateCon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin: 1em auto;
  }
`;

const PopCon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Para = styled.p``;

const Header = styled.h3``;

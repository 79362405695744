import React from "react";
import "./top.css";
import logo from "../../../img/DANTOWN icon 1.png";
import close from "../../../img/mdi_close.png";

function SidebarTop({closeSidebar}) {
  return (
    <div className="top">
      <div className="sidebar_logo_container">
        <img src={logo} alt="logo" className="sidebar_logo" />
      </div>
      <div className="sidebar_close_icon">
        <img src={close} alt="close_sidebar" id="close" onClick={() => closeSidebar()} />
      </div>
    </div>
  );
}

export default SidebarTop;

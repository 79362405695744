import http from "./httpService";
import config from "../../config/config.json";

export function buyNaira(authToken, data) {
  const url = `${config.apiEndPoint}/buy_naira`;
  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "multipart/form-data",
  };
  return http.post(url, data, { headers: headers });
}

export function uploadPop(authToken, data) {
  const url = `${config.apiEndPoint}/upload-pop`;
  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "multipart/form-data",
  };
  return http.post(url, data, { headers: headers });
}

export function getAgents(authToken) {
  const url = `${config.apiEndPoint}/agents`;
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };
  return http.get(url, { headers: headers });
}

export function getTransactions(authToken) {
  const url = `${config.apiEndPoint}/transactions`;
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };
  return http.get(url, { headers: headers });
}

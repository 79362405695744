import React from "react";

const Trigger = ({ src, buttonRef, showModal }) => {
  if (src) {
    return (
      <img
        ref={buttonRef}
        onClick={showModal}
        src={src}
        style={{ width: "100px", height: "100px" }}
        alt=""
      />
    );
  }

  return <div>No proof of payment uploaded</div>;
};

export default Trigger;

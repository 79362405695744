import React from "react";
import styled from "styled-components";
import logo from '../img/Dantown-Logo-ASSETS 1.svg'

const LogoContainer = () => {
  return (
    <Container>
      <Image src={logo} alt="" />
    </Container>
  );
};

export default LogoContainer;

const Container = styled.div`
  margin-bottom: 2em;
`;

const Image = styled.img``

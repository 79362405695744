import React from "react";
import "./not_found.css";
import { Link } from "react-router-dom";
import not_found_icon from "../img/not found icon.svg";

function NotFound(props) {
  return (
    <div>
      <div className="not_found_container">
        <div className="not_found_icon_container">
          <img src={not_found_icon} alt="not_found_icon" />
        </div>
        <h3>Feeling Lost? Let's take you home</h3>
        <div className="return_home_button_container">
          <Link to="/dashboard" id="return_home_link">
            <button className="return_home_button">Back home</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;

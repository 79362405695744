import React from 'react';
import Logo from './Logo/logo';
import MobileNav from './Mobile Nav/mobileNav';
import NavCenter from './Nav Center/center';
import NavLeft from './Nav Left/left';
import NavRight from './Nav Right/right';
import './navbar.css'

function Navbar({ sidebarOpen, openSidebar }) {
    return (
        <div id="nav">
            <div className="logo">
                <Logo />
            </div>
            <div className="nav_items">
                <MobileNav openSidebar={openSidebar}/>
               <NavLeft />
               <NavCenter />
               <NavRight /> 
            </div>
        </div>
    );
}

export default Navbar;
import React from "react";
import './center.css'

function NavCenter() {
  return (
    <div className="center">
      <div className="nav_btn_container">
        <a className="nav_btn nav_btn-primary" href="#">
          Quick Top up
        </a>
      </div>
      <div className="nav_btn_container">
        <a className="nav_btn nav_btn-light" href="#">
          Quick withdrawal
        </a>
      </div>
      <div className="nav_btn_container">
        <a className="nav_btn nav_btn-info" href="#">
          Swap Bitcoins
        </a>
      </div>
    </div>
  );
}

export default NavCenter;

import React from "react";
import './logo.css'
import logo from "../../../img/DANTOWN icon 1.png";

function Logo(props) {
  return (
    <div className="desktop_logo">
      <div className="nav_logo_container">
        <img src={logo} alt="logo" className="logo" />
      </div>
    </div>
  );
}

export default Logo;

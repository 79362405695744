import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import './App.css';
import DashboardIndex from "./components/Dashboard/App";
import LoginForm from "./components/Login/LoginForm";
import NotFound from "./components/Not Found/not_found";
import ProtectedRoute from "./components/Utils/ProtectedRoutes/protectedRoute";
import { AuthProvider } from "./contexts";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <ProtectedRoute path="/dashboard" component={DashboardIndex} />
          <Route path="/login" component={LoginForm} />
          <Redirect from="/" exact to="/dashboard" />
          <ProtectedRoute path="/not-found" component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;

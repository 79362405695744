import React, { useEffect, useState } from "react";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { getTransactions } from "../../../Services/transactionsService";
import BackHeader from "../../../Utils/Back Header/back_header";
import IndividualTransaction from "./individualTransaction";

const Transactions = ({ authToken }) => {
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    const getTransaction = async () => {
      if (mounted) {
        try {
          const data = await getTransactions(authToken);
          setTransactions(data.data.data);
          setErrorLoading(false);
          setLoadingTransactions(false);
        } catch (error) {
          setErrorLoading(true);
          setLoadingTransactions(false);
        }
      }
    };
    getTransaction();
    return () => (mounted = false);
  }, []);

  return (
    <Container>
      <BackHeader back_link={"/dashboard"} />
      <Content>
        {loadingTransactions ? (
          <Spinner
            name="folding-cube"
            color="#000070"
            className="spin_kit_icon"
          />
        ) : errorLoading ? (
          <div className="error_loading">
            An error occurred, please try again
          </div>
        ) : (
          transactions.map((item, index) => (
            <IndividualTransaction
              key={index}
              amount={item.amount}
              pop={item.pops}
              date={item.updated_at}
            />
          ))
        )}
      </Content>
    </Container>
  );
};

export default Transactions;

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 1em auto;
  padding-top: 2em;
`;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from "../../../contexts";

function ProtectedRoute({ path, component: Component, render, ...rest }) {
  const userDetails = useAuthState();
  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (!userDetails.token)
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}

export default ProtectedRoute;

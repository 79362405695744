import React from 'react';
import SidebarBottom from './Bottom/bottom';
import SidebarMiddle from './Middle/middle';
import './sidebar.css'
import SidebarTop from './Top/top';

function Sidebar({ sidebarOpen, closeSidebar }) {
    return (
        <div id="sidebar_container" className={sidebarOpen ? "sidebar__responsive" : ""}>
            <div className="sidebar">
            <SidebarTop closeSidebar={closeSidebar}/>
            <SidebarMiddle />
            <SidebarBottom />
            </div>
        </div>
    );
}

export default Sidebar;
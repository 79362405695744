import React from "react";
import { Link } from "react-router-dom";
import "./back_header.css";
import back_icon from "../../img/back icon.svg";

function BackHeader({
  right_section,
  back_link = "/",
  disabled = false,
  handleCancel,
}) {
  return (
    <div className="back_header">
      <button className="back_header_left" disabled={disabled}>
        <Link to={back_link} className="back_header_link">
          <div className="back_header_icon_container">
            <img src={back_icon} alt="back_icon" />
          </div>
          <p>Back</p>
        </Link>
      </button>
      <div onClick={() => handleCancel()}>{right_section}</div>
    </div>
  );
}
BackHeader.defaultProps = {
  handleCancel: () => {},
};
export default BackHeader;
